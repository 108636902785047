import {store} from '../store'
import {ZONE_ADMIN, ZONE_DEMO, ZONE_PUBLIC} from '@/router'

let debugSH = false

export const SYSTEM_ACTION = 'system'
export const USER_ACTION = 'user'

class StoreHelperWorker {

  inZoneAdmin () { return store.getters['system/pageZone'] === ZONE_ADMIN}
  inZoneDemo () { return store.getters['system/pageZone'] === ZONE_DEMO}
  inZonePublic () { return store.getters['system/pageZone'] === ZONE_PUBLIC}

  isAuthd () { return store.getters['authStore/isAuthd']}
  // isDemo see demo section



  sentryEnvironment () {
    let url = window.location.origin
    if (url.includes('localhost')) {
      url = 'localhost'
    } else {
      url = url.replace(/https+:\/\//,'').replace(/\./g,'_')
    }
    console.log('sentryEnvironment', url)
    return url
  }
  isLoading () { return this._getSystemProperty('isLoading')}
  setLoading (context, value) {
    // console.log('setLoading ', context, value)
    // if (value > 0) {
    //   store.commit('system/setLoading', value)
    // } else {
    //   // delay the decrement to allow other process a chance to increment. This reduces flashing of the spinner, a lot!
    //   setTimeout(() => {
    //     store.commit('system/setLoading', value)
    //   }, 50)
    // }
  }
  setLoadingEnabled (context, enable) {
    store.commit('system/setLoadingEnable', enable)
  }
  setApiError (msg) {  store.commit('system/setApiError', msg, { root: true }) }
  setSystemMessage (msg) {  store.commit('system/setSystemMessage', msg, { root: true }) }

  /* **********
   * **********   Admin related    **************
   */
  adminLogin (adminPassword) {
    StoreHelper.postActionEvent(ADMIN_ACTION,'adminLogin')
    return this._dispatchAuthStore('adminLogin', { adminPassword })
  }

  async adminValidate () {
    const r = await this._dispatchAuthStore('adminValidate')
    return r.isAdmin
  }

  /* **********
   * **********   API    **************
   */
  async loadApiData () {
    if (debugSH) console.log('Load API data')
    return this._dispatchSystem('loadApiSystem')
  }
  async getApiData () {
    return await store.dispatch('system/getApiData')
  }
  async getAppTitle () {
    let apiData = await this.getApiData()
    return apiData.appTitle
  }
  async getAppVersion () {
    const apiData = await this.getApiData()
    return apiData.appVersion
  }

  postActionEvent (role, actionTag) {
    setTimeout( async () => {
      const payload = {}
      payload.isDemo = StoreHelper.isDemoMode()
      payload.role = role
      payload.action = actionTag
      payload.userId = StoreHelper._getUserProperty('userId')
      payload.consumerId = StoreHelper.toolConsumerId()
      await StoreHelper._dispatchSystem('postActionEvent', payload)
    }, 50)
  }

  /* **********
   * **********   Authorization related    **************
   */

  async fetchAndStoreRefreshToken (refreshToken) {
    StoreHelper.postActionEvent(SYSTEM_ACTION,'refreshToken')
    return await this._dispatchAuthStore('fetchAndStoreRefreshToken', { refreshToken })
  }

  async fetchTokenData (authToken = this.getAuthToken()) {
    return await this._dispatchAuthStore('fetchData', {authToken})
  }
  async storeReplaceToken (replaceToken) {
    StoreHelper.postActionEvent(SYSTEM_ACTION,'replaceToken')
    return await this._dispatchAuthStore('storeReplaceToken', {replaceToken})
  }

  getAuthData () {
    return this._getAuthStore('authData')
  }
  getAuthDataSync () {
    return this._getAuthStore('authData')
  }

  getAuthToken () {
    return this._getAuthStore('token')
  }
  getAuthVisitId () {
    return this._getAuthStore('visitId')
  }
}

const StoreHelper = new StoreHelperWorker()
export default StoreHelper
